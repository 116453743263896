import PasswordLoginForm from '../../components/auth/password/PasswordLoginForm';
import ThirdPartyProvidersSection from '../../components/auth/third-party/ThirdPartyProvidersSection';
import Card from '../../components/Card';
import Divider from '../../components/Divider';

/**
 * Login form component. Renders buttons to login with a google,
 * or microsoft account. Also renders a form to login with a password.
 *
 * @example <SelectProviderSection />
 */
export default function SelectProviderSection() {
  return (
    <Card className="space-y-8 p-12">
      <h1 className="mb-8 text-center text-3xl font-bold text-gray-800">Iniciar sesi&oacute;n</h1>
      <ThirdPartyProvidersSection />
      <Divider label="O ingrese su nombre de usuario" />
      <PasswordLoginForm />
    </Card>
  );
}

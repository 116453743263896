import clsx from 'clsx';

interface Props {
  id?: string;
  checked?: boolean;
  onToggle: () => void;
  disabled?: boolean;
}

export default function Switch({ id, onToggle, checked = false, disabled = false }: Props) {
  return (
    <button
      id={id}
      type="button"
      role="switch"
      className="group relative inline-flex h-5 w-10 flex-shrink-0 cursor-pointer items-center justify-center rounded-full focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2"
      onClick={onToggle}
      disabled={disabled}
    >
      <span className="pointer-events-none absolute h-full w-full rounded-md bg-white"></span>
      <span
        className={clsx(
          'pointer-events-none absolute mx-auto h-4 w-9 rounded-full transition-colors duration-200 ease-in-out',
          {
            'bg-gray-200': !checked,
            'bg-blue-700': checked && !disabled,
            'bg-blue-200': checked && disabled,
          },
        )}
      ></span>

      <span
        className={clsx(
          'pointer-events-none absolute left-0 inline-block h-5 w-5 transform rounded-full border border-gray-200 bg-white shadow ring-0 transition-transform duration-200 ease-in-out',
          { 'translate-x-0': !checked, 'translate-x-5': checked },
        )}
      ></span>
    </button>
  );
}

import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { showErrorMessage, showSuccessMessage } from '../../../lib/notifications';
import Card from '../../Card';
import RequestInstalinkForm from './RequestInstalinkForm';

/**
 * Component that renders a form to request an instalink. When the form
 * is submitted a post request is made to the /auth/instalink endpoint.
 *
 * @example <InstalinkCard />
 */
export default function RequestInstalinkCard() {
  const navigate = useNavigate();

  const requestInstalink = async ({ email }: { email: string }) => {
    try {
      await axios.post('/auth/instalink', { email });

      showSuccessMessage('Se han enviado instrucciones a su correo electrónico');
      navigate('/login');
    } catch (error) {
      showErrorMessage('Se produjo un error al enviar el enlace');
    }
  };

  return (
    <Card className="space-y-8 p-12">
      <div className="mb-52 space-y-8">
        <h1 className="mb-8 text-center text-3xl font-bold">Recuperar contrase&ntilde;a</h1>
        <div className="space-y-8">
          <p>Ingrese su correo electr&oacute;nico para recibir instrucciones.</p>
          <RequestInstalinkForm onSubmit={requestInstalink} />
        </div>
      </div>
    </Card>
  );
}

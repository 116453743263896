import clsx from 'clsx';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import { object, string } from 'yup';

interface Props {
  onSubmit: (data: { email: string }) => void;
}

/**
 * Form to request an instant link for the user whose email is given in the form.
 *
 * @example <RequestInstalinkForm onSubmit={onSubmit} />
 */
export default function RequestInstalinkForm({ onSubmit }: Props) {
  const initialState = { email: '' };
  const validationSchema = object().shape({
    email: string().email('Dirección de correo inválida').required('Este campo es obligatorio'),
  });

  const handleSubmit = (data) => onSubmit(data);

  return (
    <Formik
      initialValues={initialState}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
    >
      {({ isSubmitting, errors, touched }) => (
        <Form className="space-y-4">
          <div className="relative">
            <Field
              id="email"
              type="email"
              name="email"
              placeholder="Correo electrónico"
              className={clsx('form-input rounded-md', {
                invalid: !!errors.email && touched.email,
              })}
            />
            <label htmlFor="email">Correo electrónico</label>
            <ErrorMessage name="email" component="div" className="form-input-error-message" />
          </div>
          <div>
            <button type="submit" className="primary-button w-full" disabled={isSubmitting}>
              Enviar instrucciones
            </button>
          </div>
        </Form>
      )}
    </Formik>
  );
}

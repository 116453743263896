import clsx from 'clsx';

interface Props {
  children: React.ReactNode;
  className?: clsx.ClassValue;
}

/**
 * Component that renders a Tailwind container.
 *
 * @example <Card>Content of the card</Card>
 */
export default function Card({ children, className }: Props) {
  return (
    <div className={clsx('w-full rounded-md bg-white shadow-lg print:shadow-none', className)}>
      {children}
    </div>
  );
}

import clsx from 'clsx';
import { useEffect, useState } from 'react';
import { useCustomField } from './hooks';

interface Props {
  id: string;
  name: string;
  placeholder?: string;
}

export const PhoneInputField = ({ id, name, placeholder }: Props) => {
  const { errors, value, setFieldValue, touched } = useCustomField(name);
  const [inputValue, setValue] = useState({ countryCode: '+57', number: value || '' });

  useEffect(() => {
    setFieldValue(`${inputValue.countryCode} ${inputValue.number}`);
  }, [inputValue]);

  return (
    <div className={'relative flex items-stretch rounded-md'}>
      <select
        id={id}
        name={`${name}.countryCode`}
        className="rounded-l-md border border-r-0 border-gray-200 bg-transparent px-1 text-center"
        value={inputValue.countryCode}
        onChange={(e) => setValue((val) => ({ ...val, countryCode: e.target.value }))}
      >
        <option value="+1">+1</option>
        <option value="+39">+39</option>
        <option value="+49">+49</option>
        <option value="+51">+51</option>
        <option value="+52">+52</option>
        <option value="+54">+54</option>
        <option value="+55">+55</option>
        <option value="+56">+56</option>
        <option value="+57">+57</option>
        <option value="+58">+58</option>
        <option value="+504">+504</option>
        <option value="+505">+505</option>
        <option value="+506">+506</option>
        <option value="+507">+507</option>
        <option value="+591">+591</option>
        <option value="+593">+593</option>
        <option value="+595">+595</option>
      </select>
      <input
        id={id}
        name={`${name}.number`}
        placeholder={placeholder}
        onChange={(e) => setValue((val) => ({ ...val, number: e.target.value }))}
        className={clsx('form-input rounded-r-md', {
          invalid: !!errors[name] && touched[name],
        })}
      />
      <label htmlFor="primaryPhone">Teléfono</label>
    </div>
  );
};

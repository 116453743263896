interface Props {
  children: React.ReactNode;
}

export default function SiteLayout({ children }: Props) {
  return (
    <div
      className="flex min-h-screen items-center justify-center bg-purple-700 px-4 py-12 sm:px-6 lg:px-8"
      style={{ maxHeight: '100svh' }}
    >
      {children}
    </div>
  );
}

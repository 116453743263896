import clsx from 'clsx';
import { Field, useField } from 'formik';
import Switch from './Switch';

interface Props {
  name: string;
  id: string;
  label: string;
  className?: clsx.ClassValue;
}

export default function CheckboxField({ name, label, id, className }: Props) {
  const [field, _, helpers] = useField({ name });

  return (
    <div className="flex">
      <Field
        id={id}
        name={field.name}
        type="checkbox"
        render={({ field }) => (
          <Switch
            id={field.name}
            checked={field.value}
            onToggle={() => helpers.setValue(!field.value)}
          />
        )}
      />
      <label htmlFor={id} className={clsx('ml-2 block text-sm text-gray-900', className)}>
        {label}
      </label>
    </div>
  );
}

import { useCombobox, useMultipleSelection } from 'downshift';
import { useField, useFormikContext } from 'formik';
import { find, propEq } from 'ramda';

export interface HasId {
  id: number | string;
}

export function useMultipleDropdown<T extends HasId>(
  items: T[],
  selectedItems: T[],
  onChange: (items: T[]) => void,
  inputValue: string | undefined,
  setInputValue: (value?: string) => void,
) {
  const { getSelectedItemProps, getDropdownProps, removeSelectedItem, addSelectedItem } =
    useMultipleSelection<T>({
      itemToKey: (item) => item?.id.toString() ?? '',
      selectedItems,
      onSelectedItemsChange: ({ selectedItems }) => onChange(selectedItems),
    });

  const comboboxProps = useCombobox<T>({
    items,
    inputValue,
    selectedItem: null,
    onInputValueChange: ({ inputValue, selectedItem }) => {
      // Do not trigger search when an item is clicked
      if (!selectedItem) setInputValue(inputValue);
    },
    onSelectedItemChange: ({ selectedItem }) => {
      if (find(propEq(selectedItem.id, 'id'), selectedItems)) removeSelectedItem(selectedItem);
      else addSelectedItem(selectedItem);
      setInputValue('');
    },
  });

  return { ...comboboxProps, getSelectedItemProps, getDropdownProps, removeSelectedItem };
}

export function useCustomField<T>(name: string, autoSubmit = false) {
  const [field] = useField<T>(name);
  const { errors, handleBlur, setFieldValue, submitForm, touched } = useFormikContext();

  const onChange = (item: T) => {
    setFieldValue(name, item, true);
    if (autoSubmit) setTimeout(() => submitForm(), 0);
  };

  return {
    value: field.value,
    errors,
    handleBlur,
    setFieldValue: onChange,
    touched,
  };
}

import React from 'react';

/**
 * Button component that redirects the user to the /login/azure page.
 *
 * @example <WindowsLoginButton />
 */
export default function WindowsLoginButton() {
  const onClick = () => {
    window.location.href = '/login/azure';
  };

  return (
    <button
      type="button"
      className="button w-52 text-white focus:outline-none focus:ring-2 focus:ring-blue-300 focus:ring-offset-2"
      style={{ backgroundColor: '#00adef' }}
      onClick={onClick}
    >
      Microsoft
    </button>
  );
}

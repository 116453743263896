import { Link } from 'react-router-dom';

export default function SignupLinkSection() {
  return (
    <div className="w-full text-center">
      <Link to="/signup" className="inline-block p-2 text-purple-200">
        Crear una <u>nueva cuenta</u>
      </Link>
    </div>
  );
}
